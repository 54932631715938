<template>
  <div ref="app" :class="['template05', { border: isBorder }]">
    <div class="text">
      <el-input
        v-model="contentData.title"
        :rows="2"
        resize="none"
        type="textarea"
        class="title"
      ></el-input>
      <el-input v-model="contentData.teacher" class="teacher"></el-input>
      <div class="content">
        <div v-for="(item, index) in contentData.content" :key="index" class="content-item">
          <el-input v-model="item.text" class="content-text"></el-input>
        </div>
      </div>
      <el-input v-model="contentData.date" class="date"></el-input>
      <div class="qrcode">
        <div class="des">
          <p>保存图片或使用微信</p>
          <p>>>扫一扫识别二维码</p>
        </div>
        <div class="code">
          <img :src="qrCode" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
export default {
  name: 'Template05',
  props: {
    qrCode: {
      type: String,
      default: '',
    },
    templateBackground: {
      type: String,
      default: '',
    },
    isBorder: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      contentData: {
        title: '企业如何做好\n生产与管理？',
        teacher: '某老师',
        content: [
          { text: '培训内容培训内容培训内容培训内容' },
          { text: '培训内容培训内容培训内容培训内容' },
          { text: '培训内容培训内容培训内容培训内容' },
          { text: '培训内容培训内容培训内容培训内容' },
          { text: '培训内容培训内容培训内容培训内容' },
          { text: '培训内容培训内容培训内容培训内容' },
        ],
        date: '2021.06.03 — 2021.06.06',
      },
    }
  },
  mounted() {
    this.$nextTick(() => {
      const $app = this.$refs.app
      const standardScale = 2208 / 1242
      window.addEventListener(
        'resize',
        _.debounce(() => {
          const docHeight = document.querySelector('.design-wrapper').clientHeight
          const docWidth = document.querySelector('.design-wrapper').clientWidth

          const currentScale = docHeight / docWidth
          let [scale, translate] = [0, 0]
          if (currentScale < standardScale) {
            // 以高度计算
            scale = docHeight / 2208
            const shouleWidth = 1242 * scale
            const offsetWidth = docWidth - shouleWidth
            translate = offsetWidth > 0 ? `translate(${offsetWidth / 2}px, 0)` : ''
          } else {
            // 以宽度计算
            scale = docWidth / 1242
            const shouleHeight = 2208 * scale
            const offsetHeight = docHeight - shouleHeight
            translate = offsetHeight > 0 ? `translate(0, ${offsetHeight / 2}px)` : ''
          }
          console.log(translate)
          // background:url(${this.templateBackground});
          $app.style.cssText = `
            transform: scale(${scale});
            min-width: 1242px;
            min-height: 2208px;
          `
        }),
        200,
      )

      if (document.createEvent) {
        var event = document.createEvent('HTMLEvents')
        event.initEvent('resize', true, true)
        window.dispatchEvent(event)
      } else if (document.createEventObject) {
        window.fireEvent('onresize')
      }
    })
  },
}
</script>
<style lang="scss" scoped>
@font-face {
  font-family: Alibaba-PuHuiTi-Bold;
  src: url('../../../../../font/Alibaba-PuHuiTi-Bold.otf');
}
@font-face {
  font-family: Source Han Sans CN-Medium;
  src: url('../../../../../font/SourceHanSansCN-Medium.otf');
}
@font-face {
  font-family: Source Han Sans CN-Regular;
  src: url('../../../../../font/SourceHanSansCN-Regular.otf');
}
.template05 {
  overflow: hidden;
  position: relative;
  min-width: 1242px;
  min-height: 2208px;
  background: url('../../../../../assets/posterTemplate/template05.png') no-repeat;
  background-size: 100% 100%;
  .text {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    ::v-deep.el-input {
      input {
        vertical-align: top;
        box-sizing: border-box;
        border: 0;
        border-radius: 0;
        height: auto;
        background-color: transparent;
        color: #ffffff;
      }
    }
    ::v-deep.el-textarea {
      textarea {
        box-sizing: border-box;
        border: 0;
        border-radius: 0;
        height: auto;
        background-color: transparent;
        color: #ffffff;
      }
    }
    ::v-deep.title {
      position: absolute;
      top: 218px;
      left: 89px;
      display: block;
      width: 860px;
      textarea {
        padding: 0;
        height: 342px;
        font-size: 131px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #fac908;
        line-height: 170px;
        overflow: hidden;
        &:focus {
          border: 2px dashed #ff7b33;
          border-radius: 0;
        }
      }
    }
    ::v-deep.teacher {
      position: absolute;
      top: 632px;
      left: 508px;
      display: block;
      width: 400px;
      input {
        font-size: 48px;
        font-family: Source Han Sans CN-Medium;
        font-weight: 500;
        box-sizing: border-box;
        color: #ffffff;
        height: 56px;
        &:focus {
          border: 2px dashed #ff7b33;
          border-radius: 0;
        }
      }
    }
    ::v-deep .content {
      position: absolute;
      top: 1116px;
      left: 331px;
      .content-item {
        display: flex;
        align-items: center;
        margin-bottom: 56px;
        .content-text {
          width: 693px;
          input {
            box-sizing: border-box;
            font-size: 39px;
            font-family: Source Han Sans CN-Medium;
            font-weight: 400;
            color: #282828;
            &:focus {
              border: 2px dashed #ff7b33;
              border-radius: 0;
            }
          }
        }
      }
    }
    ::v-deep.date {
      position: absolute;
      top: 750px;
      left: 337px;
      display: block;
      width: 700px;
      input {
        height: 55px;
        font-size: 48px;
        font-weight: 500;
        color: #fac908;
        font-family: Source Han Sans CN-Medium;
        &:focus {
          border: 2px dashed #ff7b33;
          border-radius: 0;
        }
      }
    }
    .qrcode {
      position: absolute;
      top: 1841px;
      left: 152px;
      display: flex;
      align-items: center;
      .code {
        width: 234px;
        height: 234px;
        margin-left: 252px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .des {
        font-size: 45px;
        font-family: Source Han Sans CN-Medium;
        font-weight: 500;
        text-align: left;
        color: #000000;
        line-height: 67px;
      }
    }
  }
  &.border {
    ::v-deep.el-input {
      input {
        border: 2px dashed #c9c9c9;
      }
    }
    ::v-deep.el-textarea {
      textarea {
        border: 2px dashed #c9c9c9;
      }
    }
  }
  img {
    height: 100%;
    -moz-user-select: none; /*火狐*/
    -webkit-user-select: none; /*webkit浏览器*/
    -ms-user-select: none; /*IE10*/
    -khtml-user-select: none; /*早期浏览器*/
    user-select: none;
    pointer-events: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
</style>
