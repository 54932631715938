<!--
 * @Description: 
 * @Author: xiawenlong
 * @Date: 2021-10-14 09:39:05
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-03-22 16:42:48
-->
<template>
  <div class="poster-design">
    <div class="header">
      <div class="left">
        <div class="title">{{ $route.meta.title }}</div>
        <div class="backBtn" @click="toList">
          <img src="@/assets/teacher/back_icon.png" alt="" />
          返回列表
        </div>
      </div>
      <div class="right">
        <div class="save" @click="submit">
          <img src="@/assets/teacher/save_icon.png" alt="" />
          保存
        </div>
        <div class="preview" @click="preview">
          <i class="el-icon-view"></i>
          预览
        </div>
        <el-button type="primary" class="download" @click="download">下载海报</el-button>
        <el-button class="download" @click="downloadQr">下载二维码</el-button>
      </div>
    </div>
    <div class="content">
      <div class="list">
        <div class="title">模板列表</div>
        <div class="template-list">
          <div
            v-for="item in templateList"
            :key="item.id"
            :title="item.templateName"
            class="template-item"
            @click="selectTemplate(item)"
          >
            <img :src="item.templatePic" />
          </div>
        </div>
      </div>
      <div v-loading="loading" class="design-wrapper">
        <component
          :is="activeTemplateLabel"
          v-show="!loading"
          ref="posterTemplate"
          class="posterTemplate"
          :is-border="isBorder"
          :qr-code="qrCode"
          :template-background="templateBackground"
        ></component>
      </div>
    </div>
    <PosterPreview ref="preview" />
  </div>
</template>
<script>
import domtoimage from 'dom-to-image'
import PosterPreview from './components/PosterPreview'
import {
  teacherPosterTemplateQuery,
  teacherPosterInviteCode,
  teacherPosterCreate,
  teacherPosterUpdate,
} from '@/api/teacher'
import to from 'await-to'
import {
  template_01,
  template_02,
  template_03,
  template_04,
  template_05,
} from './components/PosterTemplate'
var timer
export default {
  name: 'PosterDesign',
  components: { PosterPreview, template_01, template_02, template_03, template_04, template_05 },
  data() {
    return {
      id: null,
      loading: false,
      saveLoading: false,
      templateList: [],
      qrCode: '',
      dataUrl: '',
      loadingText: '海报生成中...',
      activeTemplateLabel: '',
      isBorder: true,
      templateBackground: '',
    }
  },
  async mounted() {
    await this.getTemplateList()
    this.getQrCode()
    if (this.$route.params.id) {
      this.id = this.$route.params.id
      const contentText = JSON.parse(localStorage.getItem('PosterContent'))
      const activeTemplateLabel = localStorage.getItem('activeTemplateLabel')
      this.activeTemplateLabel = activeTemplateLabel
      this.templateBackground = this.templateList.filter(
        item => item.templateLabel == activeTemplateLabel,
      )[0].templateBackground
      this.$nextTick(() => {
        this.$refs.posterTemplate.contentData = contentText
      })
    }
    //  else {
    //   this.activeTemplateLabel = 'template_01'
    // }
  },
  methods: {
    // 选择模板
    selectTemplate(item) {
      clearTimeout(timer)
      this.loading = true
      const oldTemplateLabel = localStorage.getItem('activeTemplateLabel')
      this.activeTemplateLabel = item.templateLabel
      this.templateBackground = item.templateBackground
      // console.log(oldTemplateLabel, this.activeTemplateLabel, '===============')
      if (oldTemplateLabel == this.activeTemplateLabel) {
        const contentText = JSON.parse(localStorage.getItem('PosterContent'))
        this.$nextTick(() => {
          this.$refs.posterTemplate.contentData = contentText
        })
      }
      timer = setTimeout(() => {
        this.loading = false
      }, 500)
    },
    // 获取模板列表
    async getTemplateList() {
      const [res, err] = await to(teacherPosterTemplateQuery({}))
      if (err) return this.$message.warning(err.msg)
      this.templateList = res.data
      this.activeTemplateLabel = res.data[0].templateLabel
      this.templateBackground = res.data[0].templateBackground
    },
    // 获取二维码
    async getQrCode() {
      const { reserveId } = this.$route.query
      const [res, err] = await to(teacherPosterInviteCode({ reserveId }))
      if (err) return this.$message.warning(err.msg)
      this.qrCode = res.data.qrCode
    },
    // 保存海报
    async save() {
      if (this.saveLoading) return
      this.saveLoading = true
      const loading = this.$loading({
        lock: true,
        text: this.loadingText,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      let dom = document.querySelector('.posterTemplate')
      this.isBorder = false
      try {
        const dataUrl = await domtoimage.toPng(dom, { style: { transform: 'scale(1)' } })
        loading.close()
        this.saveLoading = false
        this.isBorder = true
        if (dataUrl !== '') {
          this.dataUrl = dataUrl
          const { reserveId } = this.$route.query
          const contentText = JSON.stringify(this.$refs.posterTemplate.contentData)
          const params = new FormData()
          params.append('file', this.base64ToFile(dataUrl, 'png'))
          params.append('contentText', contentText)
          params.append('templateLabel', this.activeTemplateLabel)
          if (this.id) {
            params.append('id', this.id)
          } else {
            params.append('reserveId', reserveId)
            params.append('posterName', '未命名')
          }
          const [res, err] = await to(
            this.id ? teacherPosterUpdate(params) : teacherPosterCreate(params),
          )
          if (err) return this.$message.warning(err.msg)
          if (!this.id) this.id = res.data
          this.$message.success('保存成功')
        }
      } catch (error) {
        loading.close()
        this.saveLoading = false
        this.$message.warning('生成失败')
        console.error('oops, something went wrong!', error)
      }
    },
    // 保存并返回列表
    async submit() {
      try {
        await this.save()
        this.$router.go(-1)
      } catch (e) {
        console.log(e)
      }
    },
    toList() {
      const { reserveId } = this.$route.query
      this.$router.push(`/teacher/poster?reserveId=${reserveId}`)
    },
    // 保存并预览
    async preview() {
      try {
        await this.save()
        this.$refs.preview.open(this.dataUrl)
      } catch (e) {
        console.log(e)
      }
    },
    // 保存并下载到电脑
    async download() {
      try {
        await this.save()
        let alink = document.createElement('a')
        alink.href = this.dataUrl
        alink.download = 'poster.png'
        alink.click()
      } catch (e) {
        console.log(e)
      }
    },
    // 保存并下载二维码
    async downloadQr() {
      try {
        let alink = document.createElement('a')
        alink.href = this.qrCode
        alink.download = 'qrCode.png'
        alink.click()
      } catch (e) {
        console.log(e)
      }
    },
    base64ToFile(urlData, fileName) {
      let arr = urlData.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      let bytes = atob(arr[1]) // 解码base64
      let n = bytes.length
      let ia = new Uint8Array(n)
      while (n--) {
        ia[n] = bytes.charCodeAt(n)
      }
      return new File([ia], fileName, { type: mime })
    },
  },
}
</script>
<style lang="scss" scoped>
.poster-design {
  .header {
    position: relative;
    z-index: 99;
    box-sizing: border-box;
    width: 100%;
    height: 60px;
    background: #ffffff;
    box-shadow: 0px 3px 8px 0px rgba(172, 172, 172, 0.15);
    display: flex;
    align-items: center;
    padding: 0 19px 0 16px;
    justify-content: space-between;
    .left,
    .right {
      display: flex;
      align-items: center;
    }
    .title {
      height: 28px;
      font-size: 18px;
      font-weight: 500;
      color: #333333;
      margin-right: 18px;
    }
    .backBtn {
      cursor: pointer;
      width: 90px;
      height: 26px;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      color: #606266;
      line-height: 24px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 13px;
        height: 13px;
        margin-right: 4px;
      }
    }
    .save,
    .preview {
      cursor: pointer;
      font-size: 16px;
      color: #656565;
      margin-right: 36px;
      display: flex;
      align-items: center;
      img {
        width: 16px;
        height: 16px;
        margin-right: 6px;
      }
      i {
        font-size: 20px;
        margin-right: 6px;
      }
    }
    .download {
      width: 128px;
      height: 36px;
      font-size: 14px;
    }
  }
  .content {
    box-sizing: border-box;
    width: 100%;
    height: calc(100vh - 200px);
    .list {
      float: left;
      width: 390px;
      height: 100%;
      background-color: #fff;
      .title {
        height: 44px;
        line-height: 44px;
        border-bottom: 1px solid #e2e2e2;
        font-size: 16px;
        font-weight: 500;
        text-align: left;
        color: #333333;
        padding-left: 16px;
      }
      .template-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        height: calc(100% - 44px);
        overflow-y: auto;
        box-sizing: border-box;
        padding: 20px 23px 0;
        .template-item {
          cursor: pointer;
          width: 160px;
          height: 285px;
          background-color: #ccc;
          margin-bottom: 24px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .design-wrapper {
      width: calc(100% - 390px);
      height: 100%;
      float: left;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #edf0f2;
    }
  }
}
</style>
